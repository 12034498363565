<template>
  <div class="jzbj" style="width:720px;">
    <div
      style="
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        line-height: 74px;
        font-size: 56px;
        text-align: center;
      "
    >
      关于我们
    </div>
    <div
      style="
        font-size: 36px;
        font-family: MicrosoftYaHeiLight;
        line-height: 48px;
        text-align: center;
        margin-bottom: 40px;
      "
    >
      ABOUT US
    </div>
    <p
      style="
        font-size: 18px;
        font-family: MicrosoftYaHei;
        line-height: 28px;
        margin-bottom: 36px;
      "
    >
      北海城湾科技发展有限公司-简称“城湾科技”，是一家集品牌策略规划设计、生态场景营造、智慧化系统研发于一体的高新科技企业。
    </p>
    <p style="font-size: 18px; font-family: MicrosoftYaHei; line-height: 28px">
      北海城湾科技发展有限公司凭借自有景区数字化运营平台、低代码量Saas化多维一体文旅门户、私域流量运营等知识产权为核心产品，结合多年沉积于国内顶部文旅企业的运营服务、数字化顶层规划设计和建设运营的实践，为客户提供一站式的规划设计，建设落地的解决方案。公司是一家集旅游目的地景区和乡村文旅规划建设，景区智慧系统研发，品牌定位策划，文旅数字化运营于一体的旅游度假目的地和乡村文旅的咨询规划和建设运营的服务商、内容提供商和集成商。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
